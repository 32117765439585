<template>
    <div class="contact-us">
        <div class="content">
            <div class="list-contain">
                <header-title text-color="#0A141C" :name="_tr('Contact')" />
                <div class="list">
                    <div
                        v-for="(item, index) in header"
                        :key="index"
                        class="list-item"
                    >
                        <div class="title">{{ item.title }}</div>
                        <div class="description">
                            <span>{{ item.description }}</span>
                            <div v-if="item.id === 'social'" class="social">
                                <a :href="item.facebook">Faceebook /</a>
                                <a :href="item.instagram">Instagram /</a>
                                <a :href="item.youtube">YouTube </a>
                            </div>
                            <a
                                :href="item.address_link"
                                target="_blank"
                                v-if="item.id === 'address'"
                                class="navig"
                            >
                                <img
                                    src="@/assets/images/location.svg"
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form">
                <header-title text-color="#0A141C" :name="_tr(name)" />
                <div :class="{ error: emptyError }" class="input-forms">
                    <input
                        class="input"
                        @mouseup="mouseleave"
                        @focus="mousedown"
                        @focusin="mousedown"
                        @mouseout="mouseleave"
                        @focusout="mouseleave"
                        v-model="fullName"
                        type="text"
                        :placeholder="_tr('Full Name')"
                    />
                    <input
                        class="input"
                        @focus="mousedown"
                        @focusin="mousedown"
                        @mouseup="mouseleave"
                        @mouseout="mouseleave"
                        @focusout="mouseleave"
                        v-model="subject"
                        type="text"
                        :placeholder="_tr('Subject')"
                    />
                    <textarea
                        class="input textarea"
                        @focus="mousedown"
                        @focusin="mousedown"
                        @mouseup="mouseleave"
                        @mouseout="mouseleave"
                        @focusout="mouseleave"
                        v-model="message"
                        type="text"
                        :placeholder="_tr('Message')"
                    ></textarea>
                    <div class="down-container">
                        <div
                            :class="{ show: !showSuccessMessage }"
                            class="check-container"
                        >
                            <label
                                :class="{ error: termsError }"
                                class="container"
                            >
                                <input
                                    v-model="checked"
                                    type="checkbox"
                                    :checked="checked"
                                />
                                <span
                                    @mousedown="termsError = false"
                                    class="checkmark"
                                ></span>
                            </label>
                            <p class="title">
                                {{ _tr("Consectetur adipiscing elit.") }}
                                <a
                                    :href="
                                        `/${activeLanguage.id}/terms-and-conditions`
                                    "
                                    target="_blank"
                                    >{{ _tr("Terms & Conditions") }}</a
                                >
                                {{
                                    _tr(
                                        "Quisque eget finibus sapien. Proin venenatis viverra nisi, vel sollicitudin ligula convallis placerat."
                                    )
                                }}
                            </p>
                        </div>
                        <div
                            :class="{ show: showSuccessMessage }"
                            class="success-container"
                        >
                            <span class="title">{{
                                _tr("Your information send successfully")
                            }}</span>
                        </div>
                    </div>
                    <button @click="sendContactInfo">{{ _tr("Send") }}</button>
                </div>
            </div>
        </div>
        <!--        <div class="success">-->
        <!--            <span class="title">{{ _tr('Send successfully') }}</span>-->
        <!--        </div>-->
    </div>
</template>

<script>
import HeaderTitle from "@/components/custom/HeaderTitle";
import first from "lodash/first";
import axios from "@/scripts/api.js";
import { SUPPORTED_LANGUAGES } from "@/constants/trans";
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            name: "Keep in Touch",
            fullName: "",
            subject: "",
            message: "",
            checked: false,
            termsError: false,
            emptyError: false,
            showSuccessMessage: false
        };
    },
    components: {
        HeaderTitle
    },
    computed: {
        ...mapGetters({
            scroll: "scroll/getScroll",
            lang: "language/getLang"
        }),
        activeLanguage() {
            return (
                SUPPORTED_LANGUAGES?.find(item => item.id === this.lang) || {}
            );
        },
        contactData() {
            return first(this.data?.list) || {};
        },
        header() {
            return [
                {
                    title: this._tr("Address"),
                    description: this.contactData.address,
                    address_link: this.contactData?.address_url,
                    id: "address"
                },
                {
                    title: this._tr("Email"),
                    description: this.contactData.email
                },
                {
                    title: "Phone",
                    description: this.contactData.phone
                },
                {
                    title: this._tr("Social Network:"),
                    youtube: this.contactData.youtube_link,
                    facebook: this.contactData.facebook_link,
                    instagram: this.contactData.instagram_link,
                    id: "social"
                }
            ];
        }
    },
    methods: {
        sendContactInfo() {
            if (
                this.fullName === "" ||
                this.subject === "" ||
                this.message === ""
            ) {
                this.emptyError = true;
                return (this.termsError = true);
            }
            if (!this.fullName && !this.subject && !this.message) {
                this.emptyError = true;
                if (!this.checked) {
                    this.termsError = true;
                    return;
                }
                return;
            }
            if (!this.checked) {
                this.termsError = true;
                return;
            }
            axios("/contactform", {
                fullName: this.fullName,
                subject: this.subject,
                message: this.message
            })
                .then(() => {
                    this.fullName = "";
                    this.subject = "";
                    this.message = "";
                    this.checked = false;
                    this.showSuccessMessage = true;
                    setTimeout(() => {
                        this.showSuccessMessage = false;
                    }, 6000);
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        mousedown(e) {
            e.preventDefault();
            e.stopPropagation();

            this.emptyError = false;
            setTimeout(() => {
                window.scrollTo(0, 0);
                document.body.scrollTop = 0;
                this.scroll.update();
            }, 300);
        },
        mouseleave() {
            setTimeout(() => {
                this.scroll.update();
            }, 300);
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.contact-us /deep/ {
    .content {
        display: flex;
        align-items: flex-start;
        padding: 90px 0 140px;
        justify-content: space-between;
        width: 65%;
        min-width: 1100px;
        margin: 0 auto;
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
            width: 85%;
            min-width: 900px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            padding: 60px 47px 100px;
            min-width: unset;
            width: 100%;
            flex-direction: column;
        }
        @media only screen and (max-width: 767px) {
            padding: 50px 16px 80px;
            min-width: unset;
            width: 100%;
            flex-direction: column;
        }

        .header-title {
            font-weight: normal;
            font-size: 55px;
            text-align: left;
            line-height: unset;

            @media only screen and (max-width: 1365px) {
                font-size: 34px;
            }
        }
    }

    .list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

        .list-item {
            display: flex;
            flex-direction: column;
            grid-gap: 13px;
            text-align: left;
            font-size: 21px;
            margin-top: 34px;

            @media only screen and (max-width: 1023px) {
                font-size: 16px;
                margin-top: 21px;
            }

            .title {
                color: #e5b676;
                font-weight: bold;
                font-size: 21px;
            }

            .description {
                color: #0a141c;
                font-size: 21px;
                font-weight: normal;
                display: flex;
                align-items: center;

                a {
                    color: #0a141c;
                    font-weight: normal;
                    font-size: 21px;
                    text-decoration: none;
                    transition: color 0.4s $ease-out;
                    margin-left: 4px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:hover {
                        color: #e5b676;
                    }
                }

                .navig {
                    @include center-children;
                    @include circle(30);
                    background: #e5b676;
                    margin-left: 5px;
                }
            }
        }
    }

    .form {
        width: 616px;
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
            width: 500px;
        }
        @media only screen and (max-width: 1023px) {
            width: 100%;
            margin-top: 55px;
        }

        .input-forms {
            display: flex;
            flex-direction: column;

            &.error {
                .input {
                    border-bottom: 1px solid #d8000c;

                    &::placeholder {
                        color: #d8000c;
                    }
                }
            }

            .input {
                color: #0a141c;
                border: none;
                border-bottom: 1px solid #e5b676;
                padding: 10px 0;
                resize: none;
                outline: none;
                font-size: 21px;
                font-weight: normal;
                transition: all 0.4s $ease-out;
                margin-top: 34px;

                &.textarea {
                    height: 100px;
                }

                &::placeholder {
                    opacity: 1;
                    color: #0a141c;
                    font-size: 21px;
                    font-weight: normal;
                    transition: all 0.4s $ease-out;
                }
            }

            button {
                all: initial;
                width: 100%;
                @include center-children;
                background: #e5b676;
                outline: none;
                color: #740b43;
                font-size: 21px;
                font-weight: bold;
                height: 60px;
                cursor: pointer;
                margin-top: 34px;

                @media only screen and (max-width: 1023px) {
                    height: 50px;
                }
            }

            .check-container {
                display: flex;
                align-items: center;
                opacity: 0;
                transition: all 0.4s $ease-out;

                &.show {
                    opacity: 1;
                    transition: all 0.4s 0.4s $ease-out;
                }

                p {
                    color: #0a141c;
                    font-size: 14px;
                    text-align: left;
                    line-height: 24px;

                    a {
                        color: #e5b676;
                        text-decoration: underline;
                    }
                }

                .container {
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    height: 25px;

                    &.error {
                        .checkmark {
                            background-color: #d8000c;
                        }
                    }
                }

                .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: #eee;
                    border: 1px solid #eee;
                    transition: all 0.4s $ease-out;
                }

                .container:hover input ~ .checkmark {
                    background-color: #ccc;
                }

                .container input:checked ~ .checkmark {
                    background-color: #e5b676;
                }

                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                .container input:checked ~ .checkmark:after {
                    display: block;
                }

                .container .checkmark:after {
                    left: 8px;
                    top: 3px;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }

    .success {
        position: absolute;
    }

    .down-container {
        margin-top: 34px;
        position: relative;
    }

    .success-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: all 0.4s $ease-out;
        pointer-events: none;
        text-align: center;
        width: 100%;

        &.show {
            opacity: 1;
            transition: all 0.4s 0.4s $ease-out;
        }

        .title {
            font-size: 21px;
            color: #28a745;
            display: block;
            text-align: center;
        }
    }
}
</style>
